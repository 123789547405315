import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { zippo } from "../utils/utilityFunctions";
import Layout from "../components/Layout";
import {  Container, 
          Grid, 
          makeStyles, 
          createStyles, 
          Typography,
          option,
          FormHelperText,
          FormControl,
          Select,
          TextField,
          Button,
          Modal,
          Link,
          }  from "@material-ui/core/";
import CancelIcon from '@material-ui/icons/Cancel';
import { StaticImage } from "gatsby-plugin-image";

import ScriptEditorImage from "../images/notes.svg";
import PhotoGalleryImage from "../images/images.svg";
import LoginImage from "../images/key.svg";

const muiStyles = makeStyles((theme) => {

  const inputTheme = {
    backgroundColor: "#2A2A2A",
    color: "#ffffff",
    borderRadius: "4px",
    border: "1px solid #ffffff",
  };

  const headingTheme = {
    color: "#1f3864",
    fontFamily: 'Arial',
    fontWeight: '700',
  }

  return createStyles({
    imageWrapper: {
      display: 'grid',
    },
    heroImage: {
      gridArea: '1/1',
      maxHeight: '550px',
    },
    heroOverlay: {
      opacity: '0.41',
      backgroundColor: '#FFFFFF',
      gridArea: '1/1',
    },
    heroContentWrapper: {
      gridArea: "1/1",
      position: "relative",
      placeItems: "center",
      display: "grid",
    },
    heroContent: {
      height: "100%",
    },
    heroHeadline:{
      ...headingTheme,
      textAlign: 'center',
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    heroSubHeader: {
      color: "#000000",
      textAlign: 'center',
      lineHeight: '1.1875rem',
      fontSize: '0.9907rem',
    },
    getQuoteForm: {
      textAlign: 'center',
    },
    getQuoteFormSelect: {
      ...inputTheme,
      '&& option, && optgroup': {
        ...inputTheme,
      },
      '&& .MuiSelect-icon': {
        color: '#FFFFFF',
      }
    },
    getQuoteZipcode:{
      ...inputTheme,
    },
    getQuoteFormSubmit:{
      padding: '15px 32px',
      backgroundColor: '#0e4d16',
      borderRadius: 0,
      fontWeight: 700,
      '&&:hover': {
        backgroundColor: '#12631c',
      }
    },
    inputError: {
      color: '#FFFFFF',
    },
    tipsContainer: {
      textAlign: 'center',
      padding: '30px 45px',
    },
    tipsHeading:{
      ...headingTheme,
      lineHeight: '2.375rem',
      fontSize: '1.4861rem',
    },
    p: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
    },
    articleImage: {
      gridArea: '1/1',
      height: '175px',
    },
    articleTitle: {
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'left',
    },
    articleExcerpt: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      textAlign: 'left',
    },
    articleReadMore: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      color: '#1c6bbd',
      textAlign: 'justify',
      cursor: 'pointer',
    },
    stepsHeading:{
      ...headingTheme,
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    stepTitle:{
      ...headingTheme,
      lineHeight: '1.5625rem',
      fontSize: '1.1146rem',
      marginTop: '20px',
    },
    stepText:{
      lineHeight: '1.25rem',
      fontSize: '0.8669rem',
    },
    stepCircle: {
      border: 0,
      background: 'rgb(240,243,245)',
      borderRadius: '50%',
      width: '150px',
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
    stepIcon: {
      height: '3.8125rem',
      width: '3.8125rem',
    },
    modal: {
      overflowY: 'scroll',
      padding:"50px",
      backgroundColor: 'rgba(0,0,0,0.65)',
      [theme.breakpoints.down('sm')]: {
        padding:"0px",
      },
    },
    modalWrapper: {
      padding:"50px",
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        padding:"20px",
      },
    },
    modalCloseIcon: {
      position: 'absolute',
      top: '-20px',
      right: '-5px',
      fontSize: '2.5em',
      border: '1px solid #FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
    },
    modalContent: {
      backgroundColor: '#ffffff',
      border: '0px solid #000',
      padding: '20px',
      textAlign: 'left',
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      color: '#37465A',
      borderRadius: '10px',
      position: 'relative',
      outline: 'none',
    },
    footerGetQuote: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    footerGetQuoteInputError: {
      color: 'red',
    }
  });
});

// yup validation schema -- overkill but easy to use
const schema = yup.object().shape({
  zipcode: yup
    .string()
    .min(5, "Zipcode must be 5 characters")
    .max(5, "Zipcode must be 5 characters")
    .matches(/(^\d{5}$)/, "Please enter a valid zipcode")
    .required("Zip Code is required"),
  vertical: yup.string().required("This field is required"),
});

const GetQuoteForm = ({className, inputErrorClassName, inputLayout = "default"}) => {
  const [validZip, setValidZip] = useState();
  const [zippoResponse, setZippoResponse] = useState();

  const classes = muiStyles();

  // react-hook-form useForm Hook
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (validZip) {
      const { city, state } = zippoResponse;

      // performs the redirect via Gatsby's navigate method.  We pass the state to be stored in sessionStorage on the TYP
      navigate("/thankyou/", { state: { ...data, city, state } });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const noString = value.replace(/\D/g, "");

    // prevents strings in this input
    setValue("zipcode", noString, {
      shouldValidate: true,
      shouldDirty: true,
    });    

    if (value && value.length === 5) {

      // calls the zippo API to get the city/state which gets stored in sessionStorage
      zippo(value).then((res) => {
        if (res) {
          clearErrors("zipcode");

          // zipcode input is not a controlled input so we ref the DOM to remove the class. 
          document.getElementById("zipcode").classList.remove("border-error");
          setZippoResponse(res);
          setValidZip(true);
        } else {
          setValidZip(false);
        }
      });
    }
  };

  useEffect(() => {
    // can't be a falsy check here need to check for explicit false value 
    if (validZip === false) {
      setError("zipcode", {
        type: "manual",
        message: "Please enter a valid zipcode.",
      });
    }
  }, [validZip, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Grid container justify={inputLayout === "inline" ? "center" : "left"}>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <Select
                id="quoteType"
                displayEmpty
                defaultValue=""
                native
                className={classes.getQuoteFormSelect}
                {...register("vertical")}
              >
                <option value="" disabled>
                  What are you looking for?
                </option>
                <option value="refinance">Refinance</option>
                <option value="purchase">Home Mortgage</option>
                <option value="dpa">Down Payment Assistance (DPA)</option>
                <option value="rto">Rent to Own</option>
                <optgroup label="Insurance">
                  <option value="auto">Auto Insurance</option>
                  <option value="home">Home Insurance</option>
                  <option value="life">Life Insurance</option>
                  <option value="medsup">Medicare Support</option>
                </optgroup>
                <option value="solar">Solar</option>
                <option value="windows">Windows</option>
                <option value="roofing">Roofing</option>
              </Select>
              <FormHelperText className={inputErrorClassName || classes.inputError}>{errors.vertical?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="zipcode"
                placeholder="Zip Code"
                name="zipcode"
                variant="outlined"
                {...register("zipcode")}
                onChange={handleChange}
                InputProps={{
                  className: classes.getQuoteZipcode,
                }}
                autoComplete="off"
                value={getValues("zipcode")}
              />
              <FormHelperText className={inputErrorClassName ||  classes.inputError}>{errors.zipcode?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 5} justify={inputLayout === "inline" ? "flex-end" : "flex-start"}>
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} md={inputLayout === "inline" ? 11 : 10} >
            <Button variant="contained" color="primary" type="submit" className={classes.getQuoteFormSubmit} fullWidth={inputLayout === "inline" ? true : false}>
              Get Quote
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const Hero = () => {
  const classes = muiStyles();
  return (
    <div className={classes.imageWrapper}>
      <StaticImage
        className={classes.heroImage}
        layout="fullWidth"
        alt="Confirm Your Savings"
        src={
          "../images/dailysavingsresource.com_Header.jpg"
        }
        formats={["auto", "webp", "avif"]}
      />
      <div className={classes.heroOverlay}/>
      <Container className={classes.heroContentWrapper} maxWidth="md">
        <Grid container direction="row"
          justify="flex-end"
          alignItems="center" 
          className={classes.heroContent}>
          <Grid item xs={12} sm={12} md={8} lg={7}>
            <br/>
            <Typography variant="h1" component="h1" className={classes.heroHeadline}>
            Daily Savings Resource
            </Typography>
            <p className={classes.heroSubHeader}>
              <strong>
              Shop, compare and save! Let us connect you with local resources to help you keep some of your hard-earned cash in your pocket! Let us know where you’re located and start saving today!
              </strong>
            </p>
            <br/><br/>
            <GetQuoteForm className={classes.getQuoteForm}/>
            <br/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Tips = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#fbfbfb',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Typography variant="h5" component="h5" className={classes.tipsHeading}>
        We’ve Got You Covered
        </Typography>
        <p className={classes.p}>
        Whether you’re in the market to lower your insurance premiums or looking for home financing options, we can help! Let us connect you with professionals that may be able to help lower your payments. 
        </p>
      </Container>
    </div>
  )
};

const Blogs = ( { setModalProps }) => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Grid container  spacing={6}>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="How do Insurance Companies Make Money?"
              src={"../images/dailysavingsresource.com_Article1.jpg"}
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
            How do Insurance Companies Make Money?
            </Typography>
            <p className={classes.articleExcerpt}>
            Insurance companies typically don’t make money on your premiums. The money collected from premiums helps insurance companies pay out on claims. The time in between collecting the premium and paying ... 
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'How do Insurance Companies Make Money?',
                description: 'How do Insurance Companies Make Money?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">How do Insurance Companies Make Money?</Typography>
                    <p>
                    By JMcHood July 21, 2019
                    </p>
                    <p>
                    Insurance companies typically don’t make money on your premiums. The money collected from premiums helps insurance companies pay out on claims. The time in between collecting the premium and paying claims is when the money making occurs for insurance companies.
                    </p>
                    <p>
                      <strong>Premiums Collected</strong><br/>
                      Let’s cover the assumption that people often make about insurance companies and premiums. Most people assume the premiums create a profit for the insurance company. In some cases they do, but not always. Insurance companies can’t predict beyond a reasonable doubt when and how much it will pay out on claims. Some years are years of large losses while other years are years of profit.
                    </p>
                    <p>
                    Some insurance companies end up paying out on life insurance only a year or two after writing the policy. A year or two of premiums won’t give an insurance company the balance they must pay out for the claim (at least in most cases). In this case, it would look like a total loss when this happens.
                    </p>
                    <p>
                    In other cases, though, insurance companies take in premiums for years (10, 20, or 30 years) and never pay a claim. Granted, the two situations typically balance one another out, but it helps insurance companies stay afloat. Will they make a profit this way? They probably won’t, but they will at least be able to stay in business.
                    </p>
                    <p>
                      <strong>Investing the Premiums</strong><br/>
                      The real meat and potatoes of an insurance company’s profits lie in the investments they make. They take your premiums and the premiums of every other policyholder and invest them. Insurance companies typically invest the premiums in stocks and bonds. Insurance companies then use the investment income that they make to pay out claims, cover administrative costs, and commissions.
                    </p>
                    <p>
                    Insurance companies strive to bring in large amounts of money and pay out only a fraction of that amount. This allows them to invest the remaining funds, earning interest or dividends on the investments, which helps them stay afloat or profitable during times of high payouts.
                    </p>
                    <p>
                      <strong>Lapsed Coverage</strong><br/>
                      Insurance companies often count on lapsed coverage plans too. The insurance underwriting process helps companies know if this will work. Any policy that ends without a payout, such as a term life insurance policy, is a lapsed policy. The insurance company made all of the premiums on the policy but never had to pay out on a claim.
                    </p>
                    <p>
                    Insurance companies can’t rely on lapsed policies because they only make up a small portion of their income, but they do happen. This helps further an insurance company’s profits and/or help it to stay in business.
                    </p>
                    <p>
                      <strong>Following the Industry</strong><br/>
                      Insurance companies also make money by following the industry. For example, the most expensive insurance company in the area probably won’t be the most profitable. If clients know they can go elsewhere for cheaper premiums, they will typically do that.
                    </p>
                    <p>
                    If an insurance company keeps its premiums competitive with the other companies in the area, though, they can make themselves profitable. While insurance companies need to keep their premiums high enough to cover their own costs, they have to find the perfect balance to ensure that they stay competitive.
                    </p>
                    <p>
                    If an insurance company strategizes appropriately, they should have reserves on hand that allow them to stay profitable even during times of high payouts. As discussed above, if an insurance company invests the money too, they compound what they can make, increasing their chances of making a profit.
                    </p>
                    <p>
                    Insurance companies create a perfect balance between charging competitive premiums and making a profit. They hire underwriters that can effectively assess risk so that they assign the proper premiums and avoid excessive payouts. Because no one can effectively predict the future, though, insurance companies rely on investment income to help him or her make a profit while making good on the agreements they provided clients to pay out in times of loss.
                    </p>
                  </React.Fragment>
                ),
              })}>READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="How a Credit Score Affects Your Car Insurance"
              src={
                "../images/dailysavingsresource.com_Article2.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
            How a Credit Score Affects Your Car Insurance
            </Typography>
            <p className={classes.articleExcerpt}>
            If you have a low credit score, you might worry what it will do to your car insurance rate. Do insurance companies even look at your credit score? While the answer is ‘yes,’ they do look at it. The ...
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'How a Credit Score Affects Your Car Insurance',
                description: 'How a Credit Score Affects Your Car Insurance',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">How a Credit Score Affects Your Car Insurance</Typography>
                    <p>
                      By JMcHood October 14, 2020
                    </p>
                    <p>
                    If you have a low credit score, you might worry what it will do to your car insurance rate. Do insurance companies even look at your credit score? While the answer is ‘yes,’ they do look at it. The true answer is more complicated than that. Unlike loans where lenders pay close attention to your credit history before looking at any other qualifying factors, insurance companies have a more complex way to determine your rates.
                    </p>
                    <p>
                      <strong>The Relationship Between Insurance and Your Credit Score</strong><br/>
                      You might wonder what your credit score would have to do with your driving. The two don’t relate at all. However, studies show that people with low credit scores often have a higher likelihood of making a claim on their auto insurance. This is why insurance companies care so much about your score.
                    </p>
                    <p>
                    If you use this relationship, it would make sense that drivers with poor credit pay higher premiums than those with good credit. Of course, there are other pieces to the puzzle; this is just the first piece insurance companies consider.
                    </p>
                    <p>
                      <strong>Determining Your Insurance Based Credit Score</strong><br/>
                      Insurance companies don’t use the same credit report that a bank would use before giving you a loan. However, they do access the same information from Experian, Trans Union, and Equifax. Rather than looking at your income, assets, and employment, though, they look at the following:
                      <ul>
                        <li>The length of your credit history</li>
                        <li>The negative events on your credit report (late payments)</li>
                        <li>The number of accounts in good standing</li>
                        <li>The number of accounts in collections</li>
                        <li>The amount of outstanding debt</li>
                        <li>The number of recent credit inquiries</li>
                      </ul>
                    </p>
                    <p>
                    The insurance credit score is then compiled based on the answers from the above questions.
                    </p>
                    <p>
                    Basically, if you have poor financial habits, it will affect you in more areas than just when you try to get new credit. Getting insurance for your car could be more expensive than you had hoped.
                    </p>
                    <p>
                      <strong>Your Credit Score Shows Your Riskiness</strong><br/>
                      Essentially, insurance companies are not trying to figure out how financially secure you are before they give you insurance. What they want to know is how risky you are because their neck is on the line. If they give you insurance and you make a claim, they have to pay it. While this is the idea of insurance, they want to charge you according to your risk. The lower your insurance-based credit score, the higher your premium.
                    </p>
                    <p>
                    The higher premiums help cover the insurance company should they need to pay out on claims for you. If you have a good insurance-based credit score, though, your premiums should be lower. Of course, the insurance company takes into consideration your driving history as well.
                    </p>
                    <p>
                    The better your driving history over the last few years, the lower your premium. If you have a low insurance-based credit score but a good driving history, they will offset one another. While you might not have the best insurance rate, it’s not the worst either.
                    </p>
                    <p>
                    On the other hand, if you have a low insurance-based credit score and a poor driving history, you can bet you’ll have a much higher premium than other drivers with good records. You pose a double risk to the insurance company because of your credit score and your poor driving history.
                    </p>
                    <p>
                    The bottom line is that your low credit score may affect your car insurance premium. The better shape you are in financially, the lower your insurance premium should be. When you work on all pieces of the puzzle, making yourself a low risk for lenders, you are in better shape to get insurance with an affordable premium.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="How to Shop for Home Insurance"
              src={
                "../images/dailysavingsresource.com_Article3.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
            How to Shop for Home Insurance
            </Typography>
            <p className={classes.articleExcerpt}>
            Shopping for home insurance is one of those tasks that you shouldn’t keep until the last minute, especially if you are buying a home. The last thing you want to do is make rash decisions when it comes ...
            </p>
            <p className={classes.articleReadMore}>
              <Link onClick={() => setModalProps({
                isOpen: true,
                title: 'How to Shop for Home Insurance',
                description: 'How to Shop for Home Insurance',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">How to Shop for Home Insurance</Typography>
                    <p>
                    By JMcHood May 22, 2019
                    </p>
                    <p>
                    Shopping for home insurance is one of those tasks that you shouldn’t keep until the last minute, especially if you are buying a home. The last thing you want to do is make rash decisions when it comes to choosing your home insurance.
                    </p>
                    <p>
                      <strong>Know the Home’s Condition</strong><br/>
                      The insurance agent will need to know several things about the home before insuring it. Basic information like the age of the home and its size are standard questions. But, you’ll also need to answer questions on the type of roof, type of electrical, type of plumbing, as a few examples. The insurance agent needs to know what the house is made of, what’s inside of it, and how well it has been maintained. All of these factors will play a role in how much they charge you for home insurance.
                    </p>
                    <p>
                      <strong>Know the Home’s History</strong><br/>
                      If you are buying a home, you should ask the seller about any recent home insurance claims. Even though you weren’t the one that made the claims on the property, it still plays a role in the cost of your insurance. Remember, the insurance company is insuring the home, not you. If there were previous claims, the insurance company will need to know why the homeowners filed them. If it was anything to do with weather, theft, or even fire, it could raise your rates for insurance because of the risk of liability that the home poses.
                    </p>
                    <p>
                      <strong>Consider Various Deductibles</strong><br/>
                      One of the best ways to save money on your home insurance is to take a higher deductible. When you shop for home insurance, ask each insurance company for quotes for various deductibles. This way you can choose the one that suits you the most.
                    </p>
                    <p>
                    When you shop for home insurance, don’t jump at the policy with the lowest premium. Look closely at the deductible. Is it a deductible that you could afford? If not, it doesn’t make sense to take that deductible. What if the unthinkable happens and you have to file a claim. If you find yourself with a $10,000 deductible, for example, that you can’t pay, your home insurance won’t help you. Look closely at all options and choose the one that offers a happy medium between the premium and deductible.
                    </p>
                    <p>
                      <strong>Only Purchase the Coverage That You Need</strong><br/>
                      Insurance policies aren’t a one-size-fits-all approach. You can customize your policy to fit your needs. Think of the coverage that you need and ask for it to be a part of your quote. Think of things like liability coverage and personal property coverage. Most policies come standard with $300,000 in liability coverage. Is that enough for you? Ask the agent how much it would cost to increase that coverage; it may not be nearly as much as you think.
                    </p>
                    <p>
                    Do you need Additional Living Expense Coverage? This coverage helps you should you have to live elsewhere while your home is rebuilt or repaired. If you don’t have the money set aside for such an emergency, it may be worth asking how much it would be to add this to your policy.
                    </p>
                    <p>
                    Personal property coverage covers your personal belongings. A standard policy may cover a small portion of your personal belongings, but if you own anything valuable, it may be more than what the policy covers. Make sure you ask for a quote for extra riders for your valuables.
                    </p>
                    <p>
                      <strong>Make Sure You Have Adequate Coverage</strong><br/>
                      Before you sign on the dotted line, make sure you know the type of coverage you are getting as there are two types:
                      <ul>
                        <li>Actual Cash Value – This insurance provides coverage for the replacement value of the home minus any depreciation. This could leave you with a deficit, forcing you to pay money out of your own pocket if you have a claim.</li>
                        <li>Replacement Cost – This policy will reimburse you for the full cost to replace your home at the current market price. The premiums for this policy are higher, but you have better protection in the end.</li>
                      </ul>
                    </p>
                    <p>
                      <strong>Shop Around</strong><br/>
                      Try to get quotes from at least three agents when you shop for home insurance. This gives you a chance to see what other companies have to offer. You can then compare premiums, deductibles, and coverage options to make the choice that is right for you.
                    </p>
                    <p>
                    Shopping for home insurance is almost as important as shopping for the home itself. You need enough coverage should you have a total loss, while having coverage that you can comfortably afford. We recommend that you shop for home insurance every few years to make sure that you have the best and most affordable policy available.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const Steps = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer}>
        <Typography variant="h2" component="h2" className={classes.stepsHeading}>
          We Bring The Pros To You In<br/>
          3 Simple Steps
        </Typography>
        <Grid container spacing={10} justify="center" className={classes.tipsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={ScriptEditorImage} alt="Fill us in." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Fill us in.
            </Typography>
            <p className={classes.stepText}>
              Share some basic info to get customized service.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={PhotoGalleryImage} alt="Take your pick." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Take your pick.
            </Typography>
            <p className={classes.stepText}>
              Compare best estimates from top companies.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={LoginImage} alt="Ink your deal." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Ink your deal.
            </Typography>
            <p className={classes.stepText}>
              Connect with a local agent to get a quote or apply online.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const FooterGetQuote = () =>  {
  const classes = muiStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.footerGetQuote}>
      <Grid item xs={10} lg={7} xl={5}>
        <GetQuoteForm className={classes.getQuoteForm} inputErrorClassName={classes.footerGetQuoteInputError} inputLayout="inline" />
      </Grid>
    </Grid>
  );
};

const IndexPage = () => {
  const classes = muiStyles();

  const [ modalProps, setModalProps ] = useState({ isOpen: false, title: '', description: '', content: ''});

  const closeModal = () =>{
    setModalProps({ isOpen: false, title: '', description: '', content: ''});
  }

  return (
    <Layout>
      <Hero />
      <Tips />
      <Blogs setModalProps={setModalProps} />
      <Steps />
      <FooterGetQuote />
      <Modal
        open={modalProps.isOpen}
        onClose={closeModal}
        className={classes.modal}
        id="blog-modal"
        disableBackdropClick
      >
        
        <div className={classes.modalWrapper}>
          <Container className={classes.modalContent}>
            <CancelIcon className={classes.modalCloseIcon} onClick={closeModal}/>
              {modalProps.content}
          </Container>
        </div>
      </Modal>
    </Layout>
  )
};

export default IndexPage;
